@mixin span_icon {
  background-color: unset;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% 60%;
}

.close_icon_button {
  background-image: url("../Images/close_icon.svg");
  @include span_icon;
}

.search_icon {
  background-image: url("../Images/search_icon.svg");
  @include span_icon;
}

.speak_icon {
  background-image: url("../Images/speak_icon.svg");
  @include span_icon;
}

.micro_icon {
  background-image: url("../Images/micro_icon.svg");
  @include span_icon;
}

.disable_micro_icon {
  background-image: url("../Images/disable_micro_icon.svg");
  @include span_icon;
}

.send_icon {
  background-image: url("../Images/send_icon.svg");
  @include span_icon;
}

.logout_icon {
  background-image: url("../Images/out_right_icon.svg");
  @include span_icon;
}

.stop_icon {
  background-image: url("../Images/stop_icon.svg");
  @include span_icon;
}

.person_fill_icon {
  background-image: url("../Images/person_fill_icon.svg");
  @include span_icon;
}

.lock_icon {
  background-image: url("../Images/lock_icon.svg");
  @include span_icon;
}

.lock_fill_icon {
  background-image: url("../Images/lock_fill_icon.svg");
  @include span_icon;
}

.envelope_fill_icon {
  background-image: url("../Images/envelope_fill_icon.svg");
  @include span_icon;
}

.eye_slash_icon {
  background-image: url("../Images/eye_slash_icon.svg");
  @include span_icon;
}

.eye_icon {
  background-image: url("../Images/eye_icon.svg");
  @include span_icon;
}
.arrow_repeat_icon {
  background-image: url("../Images/arrow_repeat_icon.svg");
  @include span_icon;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}