@import "Assets/css/_variable.scss";
@import "Assets/css/_fonts.scss";
@import "Assets/css/_icons.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: $black_color;
  background-color: $white_grey_color;
  font-size: 12pt;
  line-height: 1.8;
  font-weight: 400;
  font-family: Poppins, Arial, sans-serif;

  input,
  select,
  button,
  textarea {
    padding: 10px;
    font-size: 12pt;
    border-radius: 5px;
    border: unset;
    color: $black_color;
    background-color: unset;
  }

  input:focus,
  select:focus,
  button:focus,
  textarea:focus {
    outline: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $white_color inset !important;
    box-shadow: 0 0 0px 1000px $white_color inset !important;
    -webkit-text-fill-color: $black_color !important;
  }

  input:-moz-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $white_color inset !important;
    box-shadow: 0 0 0px 1000px $white_color inset !important;
    -webkit-text-fill-color: $black_color !important;
  }

  nav {
    background-color: $blue_color;
  }

  button {
    background-color: $blue_color;
    color: $white_color;
  }

  button:hover {
    cursor: pointer;
    background-color: $effect_blue_color;
  }

  a,
  a:visited {
    text-decoration: none;
    font-weight: 400;
    color: $blue_color;

  }

  a:hover {
    color: $blue_color;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $black_color;
    font-weight: 700;
  }

  form {
    a {
      font-size: 12pt;
    }

    .info_message_form,
    .error_message_form {
      font-size: 12pt;
      font-style: italic;
      display: block;
    }

    .error_message_form {
      color: #f00;
    }

    .info_message_form {
      color: #00f;
    }

    button {
      font-weight: 400;
    }
  }

  .container {
    background: $white_color;
    margin: 0 auto;
    box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
  }

  .form_group.label_icon {
    position: relative;

    input {
      border: unset;
      border-radius: unset;
      border-top: unset;
      border-left: unset;
      border-right: unset;
      border-bottom: 1px solid $grey_color;
      padding-left: 40px;
      width: 100%;
    }

    .label_before_icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-18px);
      width: 30px;
      height: 30px;
    }

    input:focus::placeholder {
      color: $black_color;
    }

    input:focus {
      border-bottom: 1px solid $black_color;
    }

  }

  .form_group.toggle_pass {
    position: relative;

    input {
      padding-right: 40px;
    }

    button.label_after_icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-18px);
      width: 30px;
      height: 30px;
      padding: unset;
    }
    button.label_after_icon:hover {
      background-color: unset;
    }
  }

  .txt_right {
    text-align: right;
  }

  .txt_center {
    text-align: center;
  }

  .txt_left {
    text-align: left;
  }


  .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;


  }

  .upper_word {
    text-transform: capitalize;
  }

  .light_bg {
    background-color: $white_color;
  }

  .normal_bg {
    background-color: $white_grey_color;
  }

  .dark_bg {
    background-color: $grey_color;
  }

  .light_color {
    color: $white_color !important;
  }

  .normal_color {
    color: $grey_color;
  }

  .dark_color {
    color: $black_color;
  }
}