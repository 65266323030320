@import "../../../../Assets/css/_variable.scss";

#dictionary_popup {
    position: fixed;
    width: 100%;
    max-width: 500px;
    bottom: 40px;
    top: 0px;
    left: 0px;
    z-index: 2;

    .dictionary_container {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-top: 15px;
        padding-bottom: 60px;
        height: 100%;
        overflow: overlay;

        &>* {
            padding: 5px 15px;
            margin: unset;
        }

        h4 {
            font-size: 18pt;
            font-weight: 700;
            text-transform: uppercase;
        }

        .dictionary_list {
            display: flex;
            gap: 10px;

            &>button {
                width: fit-content;
                background-color: $grey_color;

                &:hover {
                    background-color: $blue_color;
                }
            }
            button.active{
                background-color: $black_color;
            }
        }

        form.translate_question {
            display: grid;
            grid-template-columns: 50px 1fr 40px;
            grid-gap: 5px;
            align-items: center;

            input {
                background-color: #eee;
            }
        }

        .translate_result {
            display: grid;
            grid-template-columns: 50px 1fr;
            grid-gap: 15px;

            .translate_content {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &>* {
                    padding: unset;
                    margin: unset;
                }

                .translate_source_content_title {
                    font-size: 14pt;
                    font-weight: 700;
                }

                .translate_content_title {
                    font-size: 12pt;
                }
            }


        }

        button {
            width: 40px;
            height: 40px;
        }

        button:hover {
            background-color: unset;
        }
    }
}

@media all and (min-width: 1024px) {
    #dictionary_popup {
        left: unset;
        right: 0;
    }
}