@import "../../../Assets/css/_variable.scss";
footer{
    position: fixed;
    display: grid;
    align-items: center;
    height: 40px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $button_bg_1;
    color: $button_color_1;
    .copyright{
        width: 100%;
        max-width: 1300px;
        margin: auto;
        text-align: center;
    }
}