@import "../../../Assets/css/_variable.scss";

#previous_history {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;

    .previous_history_button {
        background-color: unset;
        color: $black_color;
        position: relative;
        padding-left: 70px;
        padding-right: 70px;
        text-align: left;
        font-size: 14pt;
        font-weight: 900;
        text-transform: uppercase;
        border-radius: unset;
        border: unset;
        width:100%;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: 50px;
            height: 100%;
            background-image: url("../../../Assets/Images/menu_app_icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60% 60%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 15px;
            width: 50px;
            height: 100%;
            background-image: url("../../../Assets/Images/out_left_icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60% 60%;
        }

    }

    .previous_history_button:hover {
        background-color: unset;
    }

    ul.conversations_box {
        position: fixed;
        display: none;
        flex-direction: column;
        list-style-type: none;
        margin: unset;
        padding-left: 15px;
        padding-top: 15px;
        width: 100%;
        height: 100%;
        top: 60px;
        left: 0;

        &>li {
            display: grid;
            align-items: center;
        }

        .conversations_box_title {
            font-size: 16pt;
            font-weight: 700;
            padding: 15px 30px;

        }

        .previous_history_link {
            padding: 0px 15px;
            text-decoration: unset;
            color: $grey_color;
        }

        .previous_history_link:hover {
            color: $blue_color;
        }

        .previous_history_link.active {
            color: $black_color;
            font-weight: 700;
        }

    }

    ul.conversations_box.active {
        display: flex;

    }
}

@media all and (min-width: 1024px) {
    #previous_history {
        position: fixed;
        top: 0;
        left: 100px;
        bottom: 40px;
        width: 300px;

        .previous_history_button {
            &::after {
                display: none;
            }
        }

        .previous_history_button:hover {
            cursor: auto;
        }

        ul.conversations_box.active {
            display: grid;

        }

        ul.conversations_box {
            position: relative;
            display: grid;
            height: unset;
            top: unset;

            .conversations_box_title,
            .close_button {
                display: none;
            }

        }

    }
}