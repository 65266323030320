$darkColor: #164863;
$alertColor: #c64444;

$black_color : #222;
$blue_color : #6dabe4;
$effect_blue_color : #4292dc;
$dark_blue_color : #DDF2FD;

$grey_color: #999;
$white_grey_color:#f8f8f8;
$white_color:#fff;

$button_bg_1: #f8f8f8;
$button_color_1: #222;


