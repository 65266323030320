#main_menu {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    grid-template-rows: 60px;
    grid-gap: 10px;
    align-items: center;
    padding: 0px 15px;
    z-index: 1;

    div {
        height: 50px;
    }
    .profile{
        grid-column: 3/4;
        grid-row: 1;
        button {
            width: 100%;
            height: 100%;
        }
    }

    .logo {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: left;

        img {
            width: 100%;
        }
    }
}

@media all and (min-width: 1024px) {
    #main_menu {
        position: fixed;
        width: 100px;
        height: 100%;
        top: 0;
        left: 0;
        grid-template-rows: 3fr 2fr;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        align-items: center;
        padding: unset;
        z-index: 1;

        .logo {
            width: 100%;
            display: flex;
            align-items:flex-start;
            justify-content: center;
            margin-bottom: auto;
            margin-top: 30px;
            img {
                width: 60px;
            }
        }
        .profile{
            grid-column: 1/2;
            grid-row: 2;
            margin-top: auto;
            margin-bottom: 20px;
            text-align: center;
            height: 60px;
            button {
                width: 60px;
                height: 60px;
            }
            button:hover {
                background-color: unset;
            }
        }
    }
}