.dictionary {
    width: 50px;

    button {
        width: 100%;
        height: 100%;
    }
    button:hover {
        background-color: unset;
    }
}

@media all and (min-width: 1024px) {

}