#message_container {
    position: fixed;
    bottom: 50px;
    right: 0;
    left: 0;

    .message_box {
        display: grid;
        grid-template-columns: 1fr 50px 50px;
        grid-gap: 5px;
        width: 100%;
        max-width: 700px;
        margin: auto;
        padding: 5px 15px;

        .message_group {
            position: relative;
            display: grid;

            textarea {
                resize: none;
            }

            button.micro_icon,
            .stop_icon {
                width: 50px;
                height: 100%;
                right: 0;
                top: 0;
            }
        }

        button.micro_icon:hover,
        button.stop_icon:hover,
        button.send_icon:hover {
            background-color: unset;
        }
    }

}

@media all and (min-width: 1024px) {
    #message_container {
        left: 400px;
    }
}