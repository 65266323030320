@import "../../../Assets/css/_variable.scss";

.auth_board {
    display: flex;
    padding: 15px;

    .auth_box {
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        display: grid;
        max-width: 900px;
        width: 90%;
        padding: 20px 15px;

        .auth_logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            padding: 15px;
            margin-bottom: 20px;

            img {
                width: 80%;
                height: fit-content;
            }
        }

        .auth_form {
            border-radius: 5px;

            form {
                display: grid;
                padding: 15px;
                grid-gap: 10px;

                .auth_title {
                    font-size: 22pt;
                    font-weight: 700;
                }

                button {
                    width: fit-content;
                    padding: 15px 40px;
                }
            }
        }

    }
}

@media all and (min-width: 1024px) {
    .auth_board {
        .auth_box {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-top: 30px;
            padding-bottom: 30px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .auth_logo.left_side {
                grid-column: 1/2;
                grid-row: 1;
            }

            .auth_logo.right_side {
                grid-column: 2/3;
                grid-row: 1;
            }

            .auth_form {
                form {
                    padding: 50px;
                    grid-gap: 20px;

                    .auth_title {
                        font-size: 20pt;
                    }
                }

                a {
                    font-weight: 600;
                }
            }

        }
    }
}