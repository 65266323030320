@import "../../../Assets/css/_variable.scss";

select::-webkit-scrollbar {
    width: 5px;
}

select::-webkit-scrollbar-thumb {
    background-color: $black_color;
    border-radius: 0;
}

select::-webkit-scrollbar-track {
    background-color: $white_grey_color;
}

select.dropdown {
    border: 1px solid black;
}
select {
    background-color: $button_bg_1 !important;
    color:$button_color_1 !important;
}