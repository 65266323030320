@import "../../../Assets/css/_variable.scss";

.content {
    padding-top: 165px;
    padding-bottom: 120px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.dictionary_box {
    position: fixed;
    right: 75px;
    top: 10px;
    height: 40px;
    z-index: 2;

    display: flex;
    gap: 5px;

    .dictionary {
        width: 40px;
        height: 100%;
    }


}

@media all and (min-width: 1024px) {

    .content {
        padding-top: 100px;
        left: 400px;
    }

    .dictionary_box {
        right: 30px;
        top: 15px;
        height: 50px;
        background-color: $button_bg_1;
        color: $button_color_1;
        border-radius: 5px;
        .dictionary {
            width: 50px;
            height: 100%;
        }
    
    
    }
}