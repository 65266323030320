#new_conversation {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    .greeting_box{
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        text-align: center;
        .greeting_logo{
            width: 100px;
            margin: auto;
        }
    }
}