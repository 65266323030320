@import "../../../Assets/css/_variable.scss";

.new_conversation {
    position: fixed;
    top: 110px;
    left: 10px;
    padding: 10px 15px;
    font-size: 11pt;
    border-radius: 5px;
    width: fit-content;
    z-index: 1;
    background-color: $button_bg_1;
    color: $button_color_1 !important;
}

#conversation_box {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 20px;
    max-height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;

    &>:last-child {
        padding-bottom: 40px;
    }

    .message_box {
        position: relative;
        display: flex;
        grid-gap: 10px;

        &>* {
            margin: unset;
        }

        .message_speech {
            position: relative;
            display: flex;
            align-items: center;
            gap: 5px;

            button {
                width: 20px;
                height: 20px;
                border-radius: unset;
                border: unset;
                background-color: unset;
                background-size: 100% 100%;
            }
        }

        .message_content {
            position: relative;
            display: flex;
            gap: 4px;
            flex-wrap: wrap;
            .raw_message_content {
                display: block;
                border: unset;
                background-color: unset;
                font-size: 12pt;
                padding: 15px 20px;
                border-radius: 10px;
            }

            .message_content_translate {
                position: absolute;
                bottom: -10px;
                width: max-content;
                max-width: 100%;
                transform: translate(0, 100%);
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 10pt;
                z-index: 1;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 0px;
                    height: 8px;
                    transform: translate(-50%, -100%);
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 8px solid $white_grey_color;

                }
            }
        }
    }

    .left_message {
        justify-content: flex-start;

        &>* {
            margin: unset;
            text-align: left;
        }

        .message_speech {
            justify-content: start;
        }

        .message_content {
            .raw_message_content {
                text-align: left;
                background-color: $blue_color;
                color: $white_color;
            }
        }
    }

    .right_message {
        justify-content: flex-start;
        flex-direction: row-reverse;
        .message_speech {
            justify-content: end;
            flex-direction: row-reverse;
        }

        .message_content {
            .raw_message_content {
                text-align: right;
                background-color: $white_grey_color;
                color: $black_color;
            }
        }

    }
}

@media all and (min-width: 1024px) {
    .new_conversation {
        top: 15px;
        left: 410px;
    }
}