.is_loading_page {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .lds_hourglass,
    .lds_hourglass:after {
        box-sizing: border-box;
    }

    .lds_hourglass {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .lds_hourglass:after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 8px;
        box-sizing: border-box;
        border: 32px solid currentColor;
        border-color: currentColor transparent currentColor transparent;
        animation: lds_hourglass 1.2s infinite;
    }

    @keyframes lds_hourglass {
        0% {
            transform: rotate(0);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        50% {
            transform: rotate(900deg);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        100% {
            transform: rotate(1800deg);
        }
    }
}

.is_loading_page:hover {
    cursor: wait;
}